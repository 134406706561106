import React from "react";

const AdminFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer id="footer" className="footer">
      <div className="copyright">
        © 2024 <strong>ReactAdmin
        </strong> &nbsp; All Rights Reserved. Powered by Folcon Engine.
      </div>
    </footer>
  );
};

export default AdminFooter;
