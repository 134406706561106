import React, { useEffect, useState } from 'react';
import Modal from "react-modal";
import { motion } from 'framer-motion';
import SectionCreate from './SectionCreate';
import '../../../../assets/css/section.css';
import { CSSTransition } from 'react-transition-group';


import { SectionList } from '../../../../service/apiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFileCirclePlus } from '@fortawesome/free-solid-svg-icons';
import SECTION_1 from "../../../../assets/images/SectionImages/Section_1.png";
import SECTION_2 from "../../../../assets/images/SectionImages/section_2.png";
import SECTION_3 from "../../../../assets/images/SectionImages/section_03.png";
import SECTION_4 from "../../../../assets/images/SectionImages/section_4.png";
import SECTION_5 from "../../../../assets/images/SectionImages/section_5.png";
import SECTION_6 from "../../../../assets/images/SectionImages/section_6.png";
import SECTION_7 from "../../../../assets/images/SectionImages/section_7.png";
import SECTION_8 from "../../../../assets/images/SectionImages/section_8.png";
import SECTION_9 from "../../../../assets/images/SectionImages/section_9.png";
import SECTION_10 from "../../../../assets/images/SectionImages/section_10.png";
import SECTION_11 from "../../../../assets/images/SectionImages/section_11.png";
import SECTION_12 from "../../../../assets/images/SectionImages/section_12.png";
import SECTION_13 from "../../../../assets/images/SectionImages/section_13.png";
import SECTION_14 from "../../../../assets/images/SectionImages/section_14.png";
import SECTION_15 from "../../../../assets/images/SectionImages/section_15.png";
import SECTION_16 from "../../../../assets/images/SectionImages/section_16.png";
import SECTION_17 from "../../../../assets/images/SectionImages/section_17.png";
import SECTION_18 from "../../../../assets/images/SectionImages/section_18.png";
import SECTION_19 from "../../../../assets/images/SectionImages/section_19.png";
import SECTION_20 from '../../../../assets/images/SectionImages/section_20.png';
import SECTION_21 from '../../../../assets/images/SectionImages/section_21.png';
import SECTION_22 from '../../../../assets/images/SectionImages/section_22.png';
import SECTION_23 from '../../../../assets/images/SectionImages/section_23.png';


const MainForm = ({ onSuccess, section }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sections, setSections] = useState([]);
  const [selectedSectionId, setSelectedSectionId] = useState('');
  const [sectionDropdownData, setSectionDropdownData] = useState([]);
  const [flippedSectionId, setFlippedSectionId] = useState(null);
  const SectionName = sectionDropdownData.map((name) => (name.section_name))
  const [imageUrl, setImageUrl] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSaveSection = (formData) => {
    setSections([...sections, formData]);
    onSuccess();
  };

  const handleSectionClick = (sectionId) => {
    setSelectedSectionId(sectionId);
    setFlippedSectionId(sectionId);
    openModal();
  };

  useEffect(() => {
    const fetchSections = async () => {
      const token = localStorage.getItem('admin_token');
      const response = await SectionList(token);
      setSectionDropdownData(response.data);
    };
    fetchSections();
  }, []);

  const ImageList = [
    { key_name: "SECTION_1", key_value: SECTION_1 },
    { key_name: "SECTION_2", key_value: SECTION_2 },
    { key_name: "SECTION_3", key_value: SECTION_3 },
    { key_name: "SECTION_4", key_value: SECTION_4 },
    { key_name: "SECTION_5", key_value: SECTION_5 },
    { key_name: "SECTION_6", key_value: SECTION_6 },
    { key_name: "SECTION_7", key_value: SECTION_7 },
    { key_name: "SECTION_8", key_value: SECTION_8 },
    { key_name: "SECTION_9", key_value: SECTION_9 },
    { key_name: "SECTION_10", key_value: SECTION_10 },
    { key_name: "SECTION_11", key_value: SECTION_11 },
    { key_name: "SECTION_12", key_value: SECTION_12 },
    { key_name: "SECTION_13", key_value: SECTION_13 },
    { key_name: "SECTION_14", key_value: SECTION_14 },
    { key_name: "SECTION_15", key_value: SECTION_15 },
    { key_name: "SECTION_16", key_value: SECTION_16 },
    { key_name: "SECTION_17", key_value: SECTION_17 },
    { key_name: "SECTION_18", key_value: SECTION_18 },
    { key_name: "SECTION_19", key_value: SECTION_19 },
    { key_name: "SECTION_20", key_value: SECTION_20 },
    { key_name: "SECTION_21", key_value: SECTION_21 },
    { key_name: "SECTION_22", key_value: SECTION_22 },
    { key_name: "SECTION_23", key_value: SECTION_23 },
  ];

  const findImageUrl = (keyName) => {
    const found = ImageList.find((image) => image.key_name === keyName);
    return found ? found.key_value : null;
  };


  const handleView = (section_id) => {
    const imageSrc = findImageUrl(section_id.toUpperCase());
    setImageUrl(imageSrc);
    setIsViewModalOpen(true);
  };


  return (
    <>
      <div className="main-form-container">
        <div className="main-form" style={{ height: '620px', overflow: 'auto' }}>
          <h2 className="main-heading">Select a Section</h2>
          <div className="section-grid">
            {sectionDropdownData.length > 0 ? (
              sectionDropdownData.map((section) => (
                <div key={section.id} className='Main-Section-div'>
                  <motion.div
                    key={section.section_id}
                    className={`section-item ${selectedSectionId === section.section_id ? 'selected' : ''}`}
                    initial={{ rotateY: 0 }}
                    animate={{ rotateY: flippedSectionId === section.section_id ? 180 : 0 }}
                    transition={{ duration: 0.6 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleView(section.section_name) // Prevent triggering parent click
                    }}
                    onMouseEnter={() => setFlippedSectionId(section.section_id)}
                    onMouseLeave={() => setFlippedSectionId(null)}
                    style={{ perspective: '1000px', cursor: 'pointer' }}
                  >
                    <motion.div
                      className="section-inner"
                      initial={{ rotateY: 0 }}
                      animate={{ rotateY: flippedSectionId === section.section_id ? 180 : 0 }}
                      transition={{ duration: 0.6 }}
                    >

                      <div className="section-front" style={{ borderRadius: '0px', fontSize: '12px' }}>
                        {section.section_name.replace(/_/g, ' ').toUpperCase()}
                      </div>
                      <div className="section-back">
                        <div className="icon-container">
                          <button
                            type="button"
                            className='plusBotton'
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </button>
                        </div>
                      </div>
                    </motion.div>
                  </motion.div>
                  <button
                    type="button"
                    className='add-Section-btn'
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log("Plus button clicked for section ID:", section.section_id);
                      handleSectionClick(section.section_id);
                    }}>
                    <FontAwesomeIcon icon={faFileCirclePlus} /></button>
                </div>


              ))
            ) : (
              <p>No sections available</p>
            )}
          </div>
          <SectionCreate
            onSuccess={onSuccess}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            onSave={handleSaveSection}
            sectionId={selectedSectionId}
          />
        </div>
      </div >
      <Modal
        isOpen={isViewModalOpen}
        onRequestClose={() => setIsViewModalOpen(false)}
        contentLabel="View Section Image"
        className="custom-modal container"
      >
        <CSSTransition
          in={isViewModalOpen}
          timeout={300}
          classNames="modal-transition"
          unmountOnExit
        >
          <div
            className="Perveiw-Model-Class"
            style={{ position: "relative", padding: "20px", textAlign: "center" }}
          >
            <button
              onClick={() => setIsViewModalOpen(false)}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                cursor: "pointer",
              }}
            >
              &times;
            </button>
            {imageUrl && (
              <img
                src={imageUrl}
                alt={SectionName}
                style={{ maxWidth: "100%", maxHeight: "80vh" }}
              />
            )}
          </div>
        </CSSTransition>
      </Modal>
    </>

  );
};

export default MainForm;
