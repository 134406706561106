import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetPagesList } from "../../../service/apiService";
import { faStreetView, faArrowLeft, faArrowRight, faPlus, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import AdminHeader from "../Dashboard/AdminHeader";
import SideBar from "../Dashboard/SideBar";
import AdminFooter from "../Dashboard/AdminFooter";

const PagesList = () => {
  const [Campaign, setCampaign] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const response = await GetPagesList(token);
      setCampaign(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = async (pageid) => {
    try {
      localStorage.setItem("pageid", pageid);
      navigate(`/admin/sections/`);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const header = (
    <div className="table-header">
      <div>
        <h3>Pages</h3>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={globalFilter}
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
            />
          </span>
        </div>
      </div>
    </div>
  );

  const startItem = pageNumber * itemsPerPage + 1;
  const endItem = Math.min((pageNumber + 1) * itemsPerPage, Campaign.length);
  const pageCount = Math.ceil(Campaign.length / itemsPerPage);
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };
  const currentCampaign = Campaign.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );

  const columns = [
    { field: "id", header: "ID", sortable: true },
    { field: "name", header: "Name", sortable: true },
    { field: "slug", header: "Slug", sortable: true },
    {
      field: "is_active",
      sortable: true,
      header: "Status",
      body: (rowData) => (
        <span className={rowData.is_active === 1 ? "active-status" : "inactive-status"}>
          {rowData.is_active === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      field: "action",
      header: "Action",
      body: (rowData) => (
        <div className="text-end">
          <div className="dropdown">
            <a
              href="#"
              data-bs-toggle="dropdown"
              className="btn btn-light rounded btn-sm font-sm"
            >
              <FontAwesomeIcon icon={faEllipsis} />
            </a>
            <div className="dropdown-menu">
              <a
                className="dropdown-item edit-icon"
                onClick={() => handleEdit(rowData.id ? rowData.id : 0)}
                style={{ cursor: "pointer" }}
              >
                Edit info
              </a>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <main id="main" className="main">
        <AdminHeader /><SideBar />
        <div className="pagetitle">
          <h1>Pages List</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item">Pages</li>
              <li className="breadcrumb-item active">List</li>
            </ol>
          </nav>
        </div>
        <br />
        <div className="pagetitle">
          <div className="searchButtonCorner">
            <div className="search-bar">
              <span className="p-input-icon-left">
                <input
                  type="search"
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                  placeholder="Search..."
                  className="custom-input"
                />
              </span>
            </div>

          </div>
        </div>
        <DataTable
          value={currentCampaign}
          globalFilter={globalFilter}
          emptyMessage="No Category records found"
          className="react-data-table"
        >
          {columns.map((col, i) => (
            <Column key={i} field={col.field} header={col.header} body={col.body} />
          ))}
        </DataTable>
        <div className="pagination-container">
          <div className="results-info">
            Showing {startItem} - {endItem} of {Campaign.length} results
          </div>
          <ReactPaginate
            previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
            nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      </main>
      <AdminFooter />
    </>
  );
};

export default PagesList;
