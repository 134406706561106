import React from "react";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import DiscountCreate from "../../pages/views/discount/Discountcreate";


const Discountcreate = () => {
    return (
        <div>
            <AdminHeader />
            <SideBar />
            <main className="main-wrap">
                <DiscountCreate />
            </main>
            <AdminFooter />
        </div>


    );

}

export default Discountcreate;