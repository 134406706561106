import React, { useEffect, useState } from "react";
import Input from "../Input";
import Dropdown from "../Dropdown";
import { VariantDropDown, productVariantSubmit } from "../../service/apiService";
import { toast } from "react-toastify";
import { CustomToast } from "../../Helper/Helper";
import "../../assets/css/ProductVariant.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
const ProductVariantsForm = ({ productId, disabled }) => {
  const [toastMessage, setToastMessage] = useState(null);
  const [variantList, setVariantList] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [variantFields, setVariantFields] = useState([{ dropdownValue: "", textboxValue: "" }]);

  useEffect(() => {
    VariantDropDownList();
  }, []);

  const VariantDropDownList = async () => {
    try {
      const response = await VariantDropDown(token);
      setVariantList(response.data);
    } catch (error) {
      console.error("Error fetching variant list:", error);
    }
  };

  const handleAddVariant = () => {
    setVariantFields([...variantFields, { dropdownValue: "", textboxValue: "" }]);
  };

  const handleDeleteVariant = (index) => {
    const updatedFields = [...variantFields];
    updatedFields.splice(index, 1);
    setVariantFields(updatedFields);
  };


  const handleChangeDropdown = (index, value) => {
    const updatedFields = [...variantFields];
    updatedFields[index].dropdownValue = value;
    setVariantFields(updatedFields);
  };

  const handleChangeTextbox = (index, value) => {
    const updatedFields = [...variantFields];
    updatedFields[index].textboxValue = value;
    setVariantFields(updatedFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (variantFields[0].dropdownValue !== "" || variantFields[0].textboxValue !== "") {
      const payload = {
        product_id: productId,
        variants: variantFields.map((variant) => ({
          variant_label_id: parseInt(variant.dropdownValue),
          value: variant.textboxValue,
          is_active: 1
        }))
      };
      const response = await productVariantSubmit(token, payload);
      if (response.status_code === 200) {
        toast.success(response.message);
        setToastMessage(response.message);
        // setVariantFields([{ dropdownValue: "", textboxValue: "" }]);
        //setVariantList([]);
        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
      }
    } else {
      toast.error("Please select variant or enter value");
    }
  };


  return (
    <>
      <div className="col-lg-12">
        {toastMessage && (
          <CustomToast message={toastMessage} onClose={() => setToastMessage(null)} />
        )}


        <div className="card-body" style={{ width: '970px', height: '650px' }}>
          <form className="form-container" onSubmit={handleSubmit}>
            {disabled ? (
              <div className="form-row">
                <div className="form-group">
                  <label className="form-label">Variant</label>
                  <Dropdown options={variantList} disabled />
                </div>
              </div>
            ) : (
              < div style={{ overflow: 'auto', height: '650px' }}>
                {variantFields.map((variant, index) => (
                  <div key={index} className="form-row">
                    <div className="form-group">
                      <label className="form-label">Variant</label>
                      <select
                        id={`variant-dropdown-${index}`}
                        className="form-select"
                        value={variant.dropdownValue}
                        onChange={(e) => handleChangeDropdown(index, e.target.value)}
                        style={{
                          width: '100%',
                          padding: '8px',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                        }}
                      >
                        {!variantList ? (
                          <option value="">No Data...</option>
                        ) : variantList.length === 0 ? (
                          <option value="">No Variant found</option>
                        ) : (
                          <>
                            <option value="">Select Variant</option>
                            {variantList.map((variantItem) => (
                              <option key={variantItem.id} value={variantItem.id}>
                                {variantItem.name.toUpperCase()}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="form-label">Variant Value</label>
                      <input
                        type="text"
                        className="form-input"
                        value={variant.textboxValue}
                        onChange={(e) => handleChangeTextbox(index, e.target.value)}
                        style={{
                          width: '100%',
                          padding: '8px',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                        }}
                      />
                    </div>

                    <button
                      type="button"
                      className="btn-delete"
                      onClick={() => handleDeleteVariant(index)}
                      style={{ background: 'transparent ', color: 'red', marginTop: '15px' }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </form>
        </div>
      </div>
      <hr></hr>
      <div className="form-actions d-flex  justify-content-evenly">
        <button type="button" className="btn-add" onClick={handleAddVariant}>
          <FontAwesomeIcon icon={faPlus} /> &nbsp;Add
        </button>

        <button type="submit" className="btn-submitt" disabled={disabled} onClick={handleSubmit}
        >
          <FontAwesomeIcon icon={faArrowUpFromBracket} />&nbsp;&nbsp;
          Submit
        </button>
      </div>
    </>



  );
};

export default ProductVariantsForm;
