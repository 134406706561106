import React, { useState, useEffect } from "react";
import ProductEditForm from "../../../components/Product/ProductEditForm";
import ProductEditImageMedia from "../../../components/Product/ProductEditImageMedia";
import ProductVideoEditMedia from "../../../components/Product/ProductVideoEditMedia";
import ProductVariantEditForm from "./ProductVariantEdit";
import ProductClassificationEditForm from "./ProductClassificationEdit";
import ProductCampaignEditForm from "./ProductCampaignEdit";
import AdminHeader from "../Dashboard/AdminHeader";
import AdminFooter from "../Dashboard/AdminFooter";
import SideBar from "../Dashboard/SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm, faRectangleList, faWindowRestore, faTag, faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";

const ProductEdit = () => {
  const [activeTab, setActiveTab] = useState('form');
  const [isProductFormSuccess, setIsProductFormSuccess] = useState(false);
  const [productId, setProductId] = useState(null);



  const handleProductFormSuccess = (productId) => {
    if (productId) {
      setIsProductFormSuccess(true);
      setProductId(productId);
      setActiveTab('form');
    }
  };
  const openTab = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <>
      <AdminHeader />
      <SideBar />
      <main id="main" className="product-create__main">
        <div className="product-create__sidebar">
          <div className="product-create__tabs">
            <button
              className={`product-create__tab ${activeTab === 'form' ? 'product-create__tab--active' : ''}`}
              onClick={() => openTab('form')}
            >
              <FontAwesomeIcon icon={faRectangleList} />
              &nbsp; &nbsp;
              <strong>Product Form</strong>
            </button>
            <button
              className={`product-create__tab ${activeTab === 'media' ? 'product-create__tab--active' : ''}`}
              onClick={() => openTab('media')}
              disabled={!isProductFormSuccess}
            >
              <FontAwesomeIcon icon={faPhotoFilm} />
              &nbsp; &nbsp;
              <strong>Product Media</strong>
            </button>
            <button
              className={`product-create__tab ${activeTab === 'variants' ? 'product-create__tab--active' : ''}`}
              onClick={() => openTab('variants')}
              disabled={!isProductFormSuccess}
            >
              <FontAwesomeIcon icon={faWindowRestore} />
              &nbsp; &nbsp;
              <strong>Variants</strong>
            </button>
            <button
              className={`product-create__tab ${activeTab === 'classification' ? 'product-create__tab--active' : ''}`}
              onClick={() => openTab('classification')}
              disabled={!isProductFormSuccess}
            >
              <FontAwesomeIcon icon={faTag} />
              &nbsp; &nbsp;
              <strong>Classification</strong>
            </button>
            <button
              className={`product-create__tab ${activeTab === 'campaign' ? 'product-create__tab--active' : ''}`}
              onClick={() => openTab('campaign')}
              disabled={!isProductFormSuccess}
            >
              <FontAwesomeIcon icon={faBullhorn} />
              &nbsp; &nbsp;
              <strong>Campaign</strong>
            </button>
            <div
              className="product-create__slider"
              style={{
                top: `${activeTab === 'form'
                  ? '0'
                  : activeTab === 'media'
                    ? '60px'
                    : activeTab === 'variants'
                      ? '110px'
                      : activeTab === 'classification'
                        ? '160px'
                        : '220px'
                  }`,
              }}
            ></div>
          </div>
        </div>
        <section className="product-create__tab-content">
          <div className={`product-create__tab-pane ${activeTab === 'form' ? 'active' : ''}`}>
            <ProductEditForm onSuccess={handleProductFormSuccess} />
          </div>
          <div className={`product-create__tab-pane ${activeTab === 'media' ? 'active' : ''}`}>
            <br />
            <ProductEditImageMedia productId={productId} />
            <br />
            <ProductVideoEditMedia productId={productId} />
            <br />
          </div>
          <div className={`product-create__tab-pane ${activeTab === 'variants' ? 'active' : ''}`}>
            <ProductVariantEditForm productId={productId} />
          </div>
          <div className={`product-create__tab-pane ${activeTab === 'classification' ? 'active' : ''}`}>
            <ProductClassificationEditForm productId={productId} />
          </div>
          <div className={`product-create__tab-pane ${activeTab === 'campaign' ? 'active' : ''}`}>
            <ProductCampaignEditForm productId={productId} />
          </div>
          <ToastContainer />
        </section>
      </main>
      <AdminFooter />

    </>
  );
};

export default ProductEdit;
