import React from "react";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../pages/views/Dashboard/SideBar";
import ProductCreate from "../../pages/views/product/Productcreate";


const Product = () => {

    return (
        <>

            <SideBar />
            <AdminHeader />
            <ProductCreate />
        </>
    );

}

export default Product;